import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "kegiatan";
const modelPath = `/aktivitas/kegiatan-mahasiswa/`;

class KegiatanPSPD extends ModelBase {
  constructor() {
    let model = {
      id: null,
      lokasi: { id: null, nama: "" },
      stase: {id: null, nama: ""},
      // verifikasi: "",
      // waktu_verifikasi: "",
      foto: null,
      // nilai_sikap: "",
      metode: {id: null, nama: ""},
      tanggal: "",
      preceptor: {id: null, nama: ""},
      pspd_id: null,
      pspd: "",
      jenis: "",
      foto_set: [],
      catatan: "",
      extra_data: {}
    };
    super(modelName, model, [], [], modelPath);
  }

  // getLoadData(data) {
  //   let nilai_sikap = data['nilai_sikap'];
  //   data['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
  //   return JSON.parse(JSON.stringify(data));
  // }

  // getPayload() {
  //   let kegiatan = this.observables[modelName];
  //   let data = {
  //     nilai_sikap: kegiatan.nilai_sikap,
  //     verifikasi: kegiatan.verifikasi
  //   };
  //   return data;
  // }
}

export default KegiatanPSPD;
